<template>
  <div class="download-app">
    <img class="icon" src="@/assets/logo/app_logo.png"></img>
    <div class="app-info">
      <div class="app-name">OMNI</div>
      <div class="app-desc">Open in the OMNI app</div>
    </div>
    <div class="open" @click="gotoApp">Download</div>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: "download-app",
    data() {
      return {
        iconAppLogo: null
      }
    },
    mounted(){
    },
    methods: {
      gotoApp() {
        location.href = "https://apps.ikrusher.com/download/omni"
      },
    },
    computed: {
    },
  }
</script>

<style lang="less" scoped>
.download-app {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8888;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background: #f1f2f2;
  .icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }
  .app-info {
    flex: 1;
    margin-left: 10px;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .app-name {
      font-weight: bold;
      font-size: 16px;
    }
    .app-desc {
      color: #666;
      margin-top:10px;
    }
  }
  .open {
    background: #3388ff;
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    font-size: 14px;
  }
}
</style>