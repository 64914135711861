/*
 * @Author: Code-HHX
 * @Date: 2022-04-19 09:41:36
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-19 16:24:36
 * @Description:用户接口
 */
import {
  requestGet,
  requestPost,
  requestGetForm,
  requestPostForm,
  requestFileUpload
} from "../config/http-json";

const user = {
  //账号注册
  async registerBrandUser(params = {}) {
    params.showProgress = true
    let response = await requestPost("/omni/user/registerBrandUser", params);
    return response;
  },
  //登录
  async doLogin(params = {}) {
    let response = await requestPostForm("/omni/user/doLogin", params);
    return response;
  },
  //找回密码发送验证码
  async sendEmail(params = {}) {
    let response = await requestPostForm("/omni/user/sendEmail", params);
    return response;
  },
  //校验验证码
  async verificationCode(params = {}) {
    let response = await requestGetForm("/omni/user/verificationCode", params);
    return response;
  },
  //查询历史记录
  async selectUploadHistory(params = {}) {
    let response = await requestGetForm("/omni/user/selectUploadHistory", params, {
      showLoading: false
    });
    return response;
  },
  //修改电压名称
  async updateVoltageName(params = {}) {
    let response = await requestPost("/omni/user/updateVoltageName", params);
    return response;
  },
  //保存电压
  async saveVoltageSettings(params = {}) {
    let response = await requestPost("/omni/user/saveVoltageSettings", params);
    return response;
  },
  //
  //updateVoltageName
  async retrievePassword(params = {}) {
    let response = await requestPostForm("/omni/user/retrievePassword", params);
    return response;
  },

  async deleteModeName(params = {}) {
    let response = await requestPostForm("/omni/user/deleteCurveById", params);
  },

  async verificationInvitationCode(params = {}) {
    const response = await requestGetForm('/omni/user/selectInvitationCode',params)
    return response
  },
  async selectSubjectBySign(params) {
    const response = await requestGetForm('/omni/user/selectSubjectBySign',params)
    return response
  },
  async uploadFile(params) {
    const response = await requestFileUpload('/omni/user/uploadFile',params)
    return response.data
  },
  async updateUserProfile(params) {
    const response = await requestPost('/omni/user/update',params)
    return response.data
  }
};

export default user;
