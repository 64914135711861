/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 11:49:13
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-19 14:47:10
 * @Description:
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api/index";
import * as utils from "@/util/utils";
import "amfe-flexible";

import echarts from "./util/vo-echarts";

import {
  Slider,
  Popup,
  Toast,
  Tab,
  Tabs,
  Dialog,
  Loading,
  DropdownMenu,
  DropdownItem,
  Switch,
  Button,
  Icon,
  List,
  PullRefresh,
  Swipe,
  SwipeItem,
  Cell,
  Image,
  Checkbox
} from "vant";

Dialog.setDefaultOptions({
  confirmButtonText: "OK",
  cancelButtonText: "Cancel",
  confirmButtonColor: "#86a1a9",
  cancelButtonColor: "#86a1a9"
});

Vue.use(Cell);
Vue.use(Slider);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Dialog);
Vue.use(Loading);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Switch);
Vue.use(Button);
Vue.use(Icon);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Image);
Vue.use(Checkbox);

//全局暴露api接口
Vue.prototype.$api = api;
Vue.prototype.$utils = utils;
Vue.prototype.$echarts = echarts;
Vue.config.devtools = process.env.NODE_ENV === "dev";
Vue.config.productionTip = false;
import DownloadApp from "./download/download-app";

setTimeout(()=> {

  if (
    navigator.userAgent.indexOf("Android") > -1 ||
    navigator.userAgent.indexOf("Linux") > -1
  ) {
    if (!navigator.bluetooth2) {
      new Vue({
        ...DownloadApp,
        store
      }).$mount("#download_omni");
    }
  } else {
    if (!navigator.bluetooth) {
      new Vue({
        ...DownloadApp,
        store
      }).$mount("#download_omni");
    }
  }
},5000)
/**
 *
 *     case unspecified = 0
 *
 *     case light = 1
 *
 *     case dark = 2
 */
if (window.setUserInterfaceStyle) {
  window.setUserInterfaceStyle(1)
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
