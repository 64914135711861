import {
  requestGet,
  requestPost,
  requestGetForm,
  requestPostForm,
  requestGetForm2
} from "../config/http-json";

const device = {
  //查询设备的曲线电压
  async deviceVoltages(params = {}) {
    let response = await requestGet("/omni/user/findRecommendedCurve", params);
    return response.data;
  },
  // async selectCustomFirmwareSettings() {
  //   let response = await requestGetForm(
  //     "/curve/selectCustomFirmwareSettings",
  //     {},
  //     { showLoading: false }
  //   );
  //   return response.data;
  // },
  async uploadConfig(
    modeId,
    modeName,
    resistance,
    maxPower,
    deviceIdentification,
    macAddress,
    firmwareVersion,
    softwareVersion,
    writeSetting,
    smokeRecordNumber,
    nameIdentification = "HB"
  ) {
    let params = {
      modeId,
      modeName,
      resistance,
      maxPower,
      deviceIdentification: deviceIdentification,
      deviceNumber: macAddress,
      firmwareVersion: firmwareVersion,
      softwareVersion: softwareVersion,
      nameIdentification,
      useVersion: 1,
      nfcSettings: writeSetting.isSupportNfc ? 1 : 0,
      preheatSetting: writeSetting.isSupportPreheat ? 1 : 0,
      preheatVoltage: writeSetting.preheatVoltage,
      preheatTouches: writeSetting.preheatCount,
      preheatTime: writeSetting.preheatTime,
      touchLight: writeSetting.isSupportLight ? 1 : 0,
      heatingVoltage: writeSetting.diyVoltage.map(item => Math.abs(item)).join(","),
      microphoneSensitivity: writeSetting.micSensitivity,
      touchSensitivity: writeSetting.touchSensitivity,
      showProgress: false,
      smokeRecordNumber,
    };
    let response = await requestPost("/omni/user/uploadFirmwareSetting", params);
    return response;
  },

  async productInfo(modelCode) {
    const data = await requestGetForm("/front/shard/findModelByCode", {tagId:modelCode.toUpperCase()}, {showProgress:false})
    console.log("ret=",  data)
    return data.data
  },
  async activeTag(modelCode) {
    const data = await requestPostForm("/omni/user/userActivateTag", {tagId:modelCode.toUpperCase()}, {showProgress:false})
    console.log("ret=",  data)
    return data.data
  },
  async theme(tagId) {
    const data = await requestGet("/omni/user/selectSubject", {tagId:tagId.toUpperCase()}, {showProgress:false})
    console.log("ret=",  data)
    return data.data
  },
};



export default device;
