<template>
  <div id="app">
    <keep-alive v-if="isLoggedIn" :include="keepAliveIncludes">
      <router-view :style="getTheme" v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view :style="getTheme" v-if="!$route.meta.keepAlive"/>
  </div>
</template>

<script>
import { TOKEN } from "@/config/http-json";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      isLoggedIn: false
    };
  },
  computed: {
    ...mapState(["keepAliveIncludes"]),
    ...mapGetters("theme", ["getTheme"])
  },
  mounted() {
    const firebaseConfig = {
      apiKey: "AIzaSyCL_eqR_8YZvmuw2OOLDy6PhXmxi3DqTPc",
      authDomain: "ikrusher-omni.firebaseapp.com",
      projectId: "ikrusher-omni",
      storageBucket: "ikrusher-omni.appspot.com",
      messagingSenderId: "186853479601",
      appId: "1:186853479601:web:540f96d655d8468d0c3e90",
      measurementId: "G-SQYK4JJ5Y7"
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    this.$store.dispatch("getConfig").then((data)=> {
      if (localStorage.getItem("APP_VERSION_CODE") < 10 || parseInt(process.env.APP_VERSION_CODE) < 10) {
        localStorage.removeItem("nfc_brand_info");
        localStorage.removeItem("nfc_brand_info_cache");
        localStorage.setItem("APP_VERSION_CODE", 10);
      }

      if (data == null) {
        const defaultBrandImage = require("@/assets/logo/logo_omni.png")
        this.$store.commit("setBrandImage", defaultBrandImage);

      }else {
        localStorage.setItem(data.brand_name, data.brand_picture);
      }

      const brandInfo = this.getCacheBrandInfo();
       if (data != null) {
        this.$store.commit("setBrandInfoInMem", {brandCover: data.brand_picture});
      }else if (brandInfo) {
        this.$store.commit("setBrandInfo", brandInfo);
      }

      const brandInfoCache = this.getCacheBrandInfoMap();
      if (brandInfoCache) {
        this.$store.commit("setBrandInfoCacheMap", brandInfoCache);
      }
    });



  },
  methods: {
    ...mapGetters(["getCacheBrandInfo","getCacheBrandInfoMap"])
  },
  watch: {
    //退出登录移除keep-alive
    $route(to, from) {
      // if the route changes...
      console.log(`to = ${to.fullPath} , from = ${from.fullPath}`);
      let token = localStorage.getItem(TOKEN);
      if (!this.$utils.isNullAndEmpty(token)) {
        // firebase returns null if user logged out
        this.isLoggedIn = true;

      } else {
        this.isLoggedIn = false;
      }
    }
  }
};
</script>

<style lang="less">
body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  font-family: Arial, Helvetica, sans-serif;
}

#app {
  width: 100%;
  height: 100%;
  background: white;
}

input {
  padding: 0 11px;
  height: 45px;
  border-radius: 8px;
  background: #e8eff2;
  opacity: 1;
  border: none;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

textarea {
  padding: 11px;
  height: 45px;
  border-radius: 8px;
  background: #e8eff2;
  opacity: 1;
  border: none;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}

input[type="text"]:focus {
  outline: none;
}

input[type="password"]:focus {
  outline: none;
}

::-webkit-input-placeholder {
  /* WebKit browsers，webkit内核浏览器 */
  color: #bcbec0;
  font-size: 18px;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #bcbec0;
  font-size: 18px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bcbec0;
  font-size: 18px;
}

button {
  height: 45px;
  background: #3fa7bc;
  border-radius: 8px;
  opacity: 1;
  border: none;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #ffffff;
}

button:focus {
  outline: none;
}

img {
  width: 50%;
}

* {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: none;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: none;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: none;
  }
}

img,
div {
  -webkit-user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
