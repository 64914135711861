import StoreType, { STATE_TYPE_OF_DEVICE } from "@/store/StoreType";
import { WriterSetting } from "@/bluetooth/BluetoothData";
import device from "@/api/device";

export const NfcStore = {
  namespaced: true,
  state: ()=> ({
    nfcDeviceOilBattery: null,//{ currentSecond:'8403',battery:'D80E',maxSecond:'08e3' },
    writerSetting: new WriterSetting(),
    level: 0,
    typeOfDevice: 0
  }),
  mutations: {
    [StoreType.COMMIT_TYPE.STATE_NFC_DEVICE_OIL_BATTERY](state, payload) {
      state.nfcDeviceOilBattery = payload;
    },
    [StoreType.COMMIT_TYPE.STATE_CURRENT_WRITER_SETTING](state, payload) {
      state.writerSetting = payload;
    },
    [StoreType.COMMIT_TYPE.STATE_DEVICE_LEVEL](state, payload) {
      state.level = payload;
    },
    [StoreType.COMMIT_TYPE.STATE_TYPE_OF_DEVICE](state, payload) {
      state.typeOfDevice = payload;
    }
  },
  actions: {
    async activeTag({state},tagId) {
      try {
        const resp = await device.activeTag(tagId)
      }catch (e) {
        console.log(e)
      }
    }
  },
  getters: {

  }
}