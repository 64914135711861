// // 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
// import * as echarts from 'echarts/lib/echarts';
// // 引入柱状图图表，图表后缀都为 Chart
// import { LineChart } from 'echarts/charts';
// // import { UniversalTransition } from 'echarts/features';
// // 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
// import {
//   GridComponent,
//   // DatasetComponent,
//   // TransformComponent
// } from 'echarts/components';
// // 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
// import { CanvasRenderer } from 'echarts/renderers';
//
// // 注册必须的组件
// echarts.use([
//   GridComponent,
//   // DatasetComponent,
//   // TransformComponent,
//   // UniversalTransition,
//   LineChart,
//   CanvasRenderer
// ]);

const echarts = require('echarts/lib/echarts');
require('echarts/lib/component/grid');
require('echarts/lib/chart/line');
require('echarts/lib/chart/pie');
require('echarts/lib/chart/bar');
require('echarts/lib/component/legend');



export default echarts;
