/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 11:49:13
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-19 17:20:29
 * @Description:
 */
import Vue from "vue";
import Vuex from "vuex";
import router from "./router";
import { BluetoothStore } from "@/store/bluetooth";
import device from "@/api/device";
import { LocalCurve, WriterSetting } from "./bluetooth/BluetoothData";
import bluetoothRepository from "./bluetooth/BluetoothRepository";
import log from "@/util/log";
import { TOKEN } from "./config/http-json";
import { NfcStore } from "@/store/nfc";
import ThemeStore, { DefaultTheme } from "@/store/theme";
import user from "@/api/user";
import {getQueryVariable} from "@/util/utils";


Vue.use(Vuex);

let configFn = '';
export default new Vuex.Store({
  state: {
    loginInfo: () => {
      try {
        const omniUserJSON = window.localStorage.getItem("omni-user");
        const userData = JSON.parse(omniUserJSON);
        if (userData.id) {
          return userData;
        }
        window.localStorage.removeItem("omni-user", null);
        window.localStorage.removeItem(TOKEN, null);
      } catch (e) {
        return null;
      }
    }, //用户信息
    disposableVoltageList: [],
    batteryVoltageList: [],
    disposableHubSetting: new WriterSetting(),
    batteryHubSetting: new WriterSetting(),
    resetHubSetting: null,
    localDisposableCurve: [
      new LocalCurve("History 1"),
      new LocalCurve("History 2"),
      new LocalCurve("History 3"),
      new LocalCurve("History 4")
    ], //里面存的对应的蓝牙设备地址，以及对应的电压曲线
    localBatteryCurve: [
      new LocalCurve("Curve 1"),
      new LocalCurve("Curve 2"),
      new LocalCurve("Curve 3")
    ], //里面存的对应的蓝牙设备地址，以及对应的电压曲线
    recommendAliasNameList: [],
    recommendAliasNameListNFC: [],
    brandInfo: null,
    brandInfoCacheMap: {},
    keepAliveIncludes: ["MyBrand", "Connect", "NewHome"],
    brandImage: null,
    hash: null
  },
  actions: {
    async loadDeviceInfo({state, commit }, tagId) {

      try {
        // if (!(navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Linux") > -1)) {
        //   let tagIdSize = tagId.length / 2;
        //   let tagIdList = [];
        //   for (let i = 0; i < tagIdSize; i++) {
        //     tagIdList.push(tagId.slice(i * 2, i * 2 + 2));
        //   }
        //   tagId = tagIdList.reverse().join("").toUpperCase();
        // }
        const result = await device.productInfo(tagId);
        const brandInfo = {
          tagId: tagId,
          supplierId: result.supplierId,
          brandCover: result.companyIcon,
          brandName: result.brandName,
          deviceType: result.deviceType,
          flavor: result.flavor,
          flavorImage: result.flavorImage
        };
        commit("setBrandInfoCacheMap", brandInfo);
        commit("setBrandInfo", brandInfo);
      } catch (e) {

      }
    },
    async loadBrandTheme({ commit ,state}, tagId) {
      if (state != null && state.hash !== '') {
        return //不换肤
      }
      const data = await device.theme(tagId.toUpperCase());
      if (data) {
        commit("theme/setTheme", data);
      } else {
        commit("theme/setTheme", DefaultTheme);
      }
    },
    _setLoginInfo({ commit }, payload) {
      commit("setLoginInfo", payload);
    },

    //#region 授权令牌无效，请重新登录
    async tokenExpired({ commit }) {
      //清空store
      commit("setLoginInfo", null);
      //清空缓存
      // localStorage.clear();
      localStorage.removeItem(TOKEN);
      router.replace({
        name: "Login" //返回首页
      });
    },

    async fetchRecommendListAliasName({ commit, state }, payload) {
      if (payload === "bluetooth") {
        const recommendAliasNameList =
          JSON.parse(
            localStorage.getItem(
              `alias_recommend_name_list_${state.loginInfo.id}_${state.bluetooth.macAddress}`
            )
          ) || [];
        commit("setRecommendAliasNameList", recommendAliasNameList);
      } else if (payload === "nfc") {
        const recommendAliasNameList =
          JSON.parse(
            localStorage.getItem(
              `nfc_alias_recommend_name_list_${state.loginInfo.id}`
            )
          ) || [];
        commit("setRecommendAliasNameListNFC", recommendAliasNameList);
      }

    },

    async setDeviceToHub({ commit, state }) {
      const currentDeviceSetting = state.bluetooth.writerSetting;
      //重新写入到hub上,写入成功更新当前的writer设置
      await bluetoothRepository.writeToWriter(currentDeviceSetting);
      commit("resetToHubSetting", currentDeviceSetting);
      commit("setDisposableSetting", currentDeviceSetting);
    },
    //#endregion

    async getConfig({commit}) {
      if (configFn) {
        return configFn;
      }
      const hash = window.location.hash.replace("#/","") || getQueryVariable("hash");
      commit("setHash", hash);

      return (configFn = new Promise((resolve, reject)=> {
        user.selectSubjectBySign({ sign: hash}).then(({data})=> {
          if (data == null) {
            resolve(data)
            return
          }
          document.title = data.brand_name;
            let brandImage
          if (data.brand_picture == null) {
            brandImage = require("@/assets/logo/logo_omni.png");
          }else {
            brandImage =  data.brand_picture;
          }
          commit("setBrandImage", brandImage);
          const themeData = {...data}
          delete themeData.brand_picture;
          themeData.hash = hash
          commit("theme/setTheme", themeData);

          const linkElement = document.querySelector('link[rel="icon"]');
          linkElement.setAttribute('href', brandImage);


          resolve(data)
        }).catch(err=> {
          reject(err);
          configFn = null
        });
      }))

    },

    async resetApp({ commit,dispatch,state }) {
      localStorage.removeItem(`nfc_current_select_mode_${state.loginInfo.id}`)
      localStorage.removeItem(`nfc_current_select_mode_${state.loginInfo.id}`)
      localStorage.removeItem(`current_select_mode_${state.loginInfo.id}`)
      localStorage.removeItem(`nfc_alias_recommend_name_list_${state.loginInfo.id}`)
      localStorage.removeItem(`alias_recommend_name_list_${state.loginInfo.id}_`)
      localStorage.removeItem(`theme`)
      await dispatch("getConfig")
      await dispatch("fetchRecommendListAliasName","bluetooth")
      await dispatch("fetchRecommendListAliasName","nfc")
    }
  },
  mutations: {
    setHash(state,payload) {
      state.hash = payload
    },
    setLoginInfo(state, payload) {
      state.loginInfo = payload;
      window.localStorage.setItem("omni-user", JSON.stringify(payload));
    },
    setDisposableRecommandVoltage(state, payload) {
      state.disposableVoltageList = payload;
    },
    setBatteryRecommandVoltage(state, payload) {
      state.batteryVoltageList = payload;
    },
    setLocalDisposableVoltage(state, payload) {
      // const macAddress = state.bluetooth.macAddress;
      const userId = state.loginInfo.id;
      log(`userId = ${userId}`);
      if (userId !== "") {
        localStorage.setItem(
          `local_disposable_voltage_curve_${userId}`,
          JSON.stringify(payload)
        );
      }
      state.localDisposableCurve = payload;
    },
    setLocalBatteryVoltage(state, payload) {
      // const macAddress = state.bluetooth.macAddress;
      const userId = state.loginInfo.id;
      if (userId !== "") {
        localStorage.setItem(
          `local_battery_voltage_curve_${userId}`,
          JSON.stringify(payload)
        );
      }
      state.localBatteryCurve = payload;
    },
    setBatterySetting(state, payload) {
      state.batteryHubSetting = new WriterSetting(payload);
    },
    setDisposableSetting(state, payload) {
      state.disposableHubSetting = new WriterSetting(payload);
    },
    resetToHubSetting(state, payload) {
      state.resetHubSetting = new WriterSetting(payload);
    },
    setRecommendAliasNameList(state, payload) {
      state.recommendAliasNameList = payload;
      window.localStorage.setItem(
        `alias_recommend_name_list_${state.loginInfo.id}_${state.bluetooth.macAddress}`,
        JSON.stringify(payload)
      );
    },
    setRecommendAliasNameListNFC(state, payload) {
      state.recommendAliasNameListNFC = payload;
      window.localStorage.setItem(
        `nfc_alias_recommend_name_list_${state.loginInfo.id}`,
        JSON.stringify(payload)
      );
    },
    setBrandInfo(state, payload) {
      if (state.hash != '' || state.hash != false) {
        return
      }
      const brandInfo = Object.assign({}, payload);
      state.brandInfo = brandInfo;
      delete brandInfo.deviceType;
      if (payload) {
        window.localStorage.setItem("nfc_brand_info", JSON.stringify(payload));
      }
    },
    setBrandInfoInMem(state, payload) {
      const brandInfo = Object.assign({}, payload);
      state.brandInfo = brandInfo;
      delete brandInfo.deviceType;
    },
    setBrandInfoCacheMap(state, payload) {
      const currentBrand = Object.assign({}, state.brandInfoCacheMap);
      currentBrand[payload.tagId] = { ...payload };

      state.brandInfoCacheMap = currentBrand;

      window.localStorage.setItem("nfc_brand_info_cache", JSON.stringify(state.brandInfoCacheMap));
    },
    setKeepAlive(state, payload) {
      if (payload.type === "remove") {
        const index = state.keepAliveIncludes.findIndex(item => item === payload.name);
        if (index !== -1) {
          state.keepAliveIncludes.splice(index, 1);
        }
      } else if (payload.type === "add") {
        const index = state.keepAliveIncludes.findIndex(item => item === payload.name);
        if (index === -1) {
          state.keepAliveIncludes.push(payload.name);
        }
      }
    },
    setBrandImage(state,payload) {
      if (payload == null) {
        state.brandImage = require("@/assets/logo/logo_omni.png");
      }else {
        state.brandImage = payload;
      }
    }
  },
  getters: {
    getLoginInfo(state) {
      return state.loginInfo;
    },
    disposableHubSetting(state) {
      return state.disposableHubSetting;
    },
    getRecommendAliasNameList(state) {
      return state.recommendAliasNameList;
    },
    getRecommendAliasNameListNFC(state) {
      return state.recommendAliasNameListNFC;
    },
    getCacheBrandInfo() {
      try {
        const nfcBrandInfo = window.localStorage.getItem("nfc_brand_info");
        const info = JSON.parse(nfcBrandInfo);
        return info;
      } catch (e) {
        return null;
      }
    },
    getCacheBrandInfoMap() {
      try {
        const nfcBrandInfoCacheMap = window.localStorage.getItem("nfc_brand_info_cache");
        const info = JSON.parse(nfcBrandInfoCacheMap);
        return info;
      } catch (e) {
        return null;
      }
    }
  },
  modules: {
    bluetooth: BluetoothStore,
    nfc: NfcStore,
    theme: ThemeStore
  }
});
