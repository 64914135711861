/*
 * @Author: Code-HHX
 * @Date: 2022-04-19 10:32:17
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-19 14:43:15
 * @Description:常用工具类
 */

//验证变量是否为空
export function isNullAndEmpty(obj) {
  if (obj == null || obj == "" || obj == undefined || obj.trim() == "") {
    return true;
  } else {
    return false;
  }
}

//验证邮箱格式
export function isEmail(str) {
  var reg = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return reg.test(str);
}

export function calcPower(voltage, r, maxPower) {
  let power = Math.pow(voltage, 2) / 1000000 / r;
  if (power >= maxPower) {
    power = maxPower;
  } else if (power <= 0.1) {
    power = 0.1;
  }
  return power;
}
export function calcMaxPower(voltage, r) {
  let power = Math.pow(voltage, 2) / 1000000 / r;
  return power;
}

export function calcVoltage(power, R) {
  return parseInt(Math.sqrt(power * 1000000 * R));
}

export function calcVoltageCeilInt(power, R) {
  return Math.ceil(Math.sqrt(power * 1000000 * R) / 100) * 100;
}
export function calcVoltageRoundInt(power, R) {
  return Math.round(Math.sqrt(power * 1000000 * R) / 100) * 100;
}
export function calcVoltageFloorInt(power, R) {
  return Math.floor(Math.sqrt(power * 1000000 * R) / 100) * 100;
}

export function toFloat(floatString, defaultValue) {
  let floatValue = parseFloat(floatString);
  if (floatValue.toString() === "NaN") {
    floatValue = defaultValue;
  }
  return floatValue;
}

export function handleMaxPowerVoltage(item, maxVoltage, minVoltage) {
  let voltage = Math.abs(item);
  if (voltage > maxVoltage) {
    voltage = maxVoltage;
  } else if (voltage < minVoltage) {
    voltage = minVoltage;
  }
  return -voltage;
}

export function handleHexStringToUInt16(hex) {
  let hexStr = hex;
  if (hexStr.length % 2 !== 0) {
    hexStr = `0${hexStr}`;
  }

  let buffer = Buffer.alloc(2);
  buffer[0] = parseInt(hexStr.slice(0, 2), 16);
  buffer[1] = parseInt(hexStr.slice(2, 4), 16);
  const dataView = new DataView(buffer.buffer);
  return dataView.getUint16(0, true);
}

export function delay(timeout){
 return new Promise((resolve) => setTimeout(resolve, timeout))
}

export function convertTagId(tagId) {
  if (!(navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("Linux") > -1)) {
    let tagIdSize = tagId.length / 2;
    let tagIdList = [];
    for (let i = 0; i < tagIdSize; i++) {
      tagIdList.push(tagId.slice(i * 2, i * 2 + 2));
    }
    tagId = tagIdList.reverse().join("").toUpperCase();
  }
  return tagId
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return (false);
}
