/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 10:35:21
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-19 09:44:57
 * @Description: api 统一出口
 */

import User from "@/api/user";

const api = { User };

export default api;
