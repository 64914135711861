import theme from "@/config/theme";

export const  DefaultTheme =  {
  themeMainColor: "#3fa7bc", //主色
  dropDownActiveColor: "#3fa7bc", //下拉框选中的颜色

  syncButtonColor: "#3fa7bc", //同步按钮颜色

  curveColor: "#86A1A9", //曲线颜色
  curveLineColor: ["rgba(192, 222, 226, 1)", "rgba(246, 250, 250, 1)"], //曲线范围的渐变色

  modeNormalBackground: "#3290a0", //推荐按钮背景颜色
  modeNormalTextColor: "#ffffff", //推荐按钮字体颜色
  modeActiveBackground: "#d5efee", //推荐按钮选中背景色
  modeActiveTextColor: "#3290a0", //推荐按钮选中文字颜色

  percentRingUnusedColor: ["#3290A0", "#9DCCD0"], //读取设备圆环的渐变色
  percentRingUseColor: "#F1F2F2", //读取设备圆环的已使用的背景色
  percentRingTextColor: "#3290A0", //圆环文字颜色

  cancelButtonColor: '#e8eff2',

  tabButtonActiveColor: "#3fa7bc",
  tabButtonInactiveColor: "#e8eff2",

  preheatActiveColor: "#b9c4c6",
  preheatInactiveColor: "#a7a9ac",

}

const ThemeStore =
  {
  namespaced: true,
  // state:    {
  //   themeMainColor: "#85A587", //主色
  //   dropDownActiveColor: "#85A587", //下拉框选中的颜色
  //
  //   syncButtonColor: "#85A587", //同步按钮颜色
  //
  //   curveColor: "#85A587", //曲线颜色
  //   curveLineColor: ["#94CF77", "#FFFFFF"], //曲线范围的渐变色
  //
  //   modeNormalBackground: "#549559", //推荐按钮背景颜色
  //   modeNormalTextColor: "#ffffff", //推荐按钮字体颜色
  //   modeActiveBackground: "#E5F6DD", //推荐按钮选中背景色
  //   modeActiveTextColor: "#549559", //推荐按钮选中文字颜色
  //
  //   percentRingUnusedColor: ["#549559", "#E5F6DD"], //读取设备圆环的渐变色
  //   percentRingUseColor: "#F1F2F2", //读取设备圆环的已使用的背景色
  //   percentRingTextColor: "#549559", //圆环文字颜色
  //
  //   cancelButtonColor: '#EBF2E8',
  //
  //   tabButtonActiveColor: "#549559",
  //   tabButtonInactiveColor: "#e8eff2",
  //
  //   preheatActiveColor: "#549559",
  //   preheatInactiveColor: "#a7a9ac",
  //
  // },
   state: ()=> {
     const themeName = "theme";
     let theme
     const hash = window.location.hash.replace("#/","");
     if (hash !== "") {
       theme = localStorage.getItem(`theme_${hash}`);
     }else {
       theme = localStorage.getItem("theme");
     }
     if (theme && theme.toString() != '{}') {
       return JSON.parse(theme)
     }else {
       return DefaultTheme;
     }
   },
  getters: {
    getTheme(state) {
      return {
        "--themeMainColor": state.themeMainColor,
        "--buttonColor": state.buttonColor,
        "--curveColor": state.curveColor,
        "--syncButtonColor": state.syncButtonColor,
        "--preheatActiveColor": state.preheatActiveColor,
        "--preheatInactiveColor": state.preheatInactiveColor,
        "--modeNormalBackground": state.modeNormalBackground,
        "--modeNormalTextColor": state.modeNormalTextColor,
        "--modeActiveBackground": state.modeActiveBackground,
        "--modeActiveTextColor": state.modeActiveTextColor,
        "--dropDownActiveColor": state.dropDownActiveColor,
        "--cancelButtonColor": state.cancelButtonColor,
        "--tabButtonActiveColor": state.tabButtonActiveColor,
        "--tabButtonInactiveColor": state.tabButtonInactiveColor,
      };
    }
  },
  mutations: {
    setTheme(state, payload) {
      const {
        themeMainColor,
        dropDownActiveColor,
        syncButtonColor,
        curveColor,
        curveLineColor,
        modeNormalBackground,
        modeNormalTextColor,
        modeActiveBackground,
        modeActiveTextColor,
        percentRingUnusedColor,
        percentRingUseColor,
        percentRingTextColor,
        tabButtonActiveColor,
        tabButtonInactiveColor,
        preheatActiveColor,
        preheatInactiveColor,
        cancelButtonColor
      } = payload;

      state.themeMainColor = themeMainColor
      state.dropDownActiveColor = dropDownActiveColor
      state.syncButtonColor = syncButtonColor
      state.curveColor = curveColor
      state.curveLineColor = curveLineColor
      state.modeNormalBackground = modeNormalBackground
      state.modeNormalTextColor = modeNormalTextColor
      state.modeActiveBackground = modeActiveBackground
      state.modeActiveTextColor = modeActiveTextColor
      state.percentRingUnusedColor = percentRingUnusedColor
      state.percentRingUseColor = percentRingUseColor
      state.percentRingTextColor = percentRingTextColor
      state.cancelButtonColor = cancelButtonColor

      state.tabButtonActiveColor = tabButtonActiveColor
      state.tabButtonInactiveColor = tabButtonInactiveColor
      state.preheatActiveColor = preheatActiveColor
      state.preheatInactiveColor = preheatInactiveColor
      if (payload.hash) {
        localStorage.setItem(`theme_${payload.hash}`, JSON.stringify(payload));
      }else {
        localStorage.setItem("theme", JSON.stringify(payload));
      }
    }
  },
  actions: {}
};
export default ThemeStore;