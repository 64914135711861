/*
 * @Author: Code-HHX
 * @Date: 2022-03-24 11:49:13
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-20 11:48:30
 * @Description:
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { TOKEN } from "@/config/http-json";
import store from "./store";
import {getQueryVariable} from "@/util/utils";

Vue.use(VueRouter);

export const routes = [
  {
    //登录页
    path: "/Login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      auth: false //是否登录鉴权
    }
  },
  // {
  //   //功能页
  //   path: "/Function",
  //   name: "Function",
  //   component: () => import("@/views/Function.vue"),
  //   meta: {
  //     auth: true //是否登录鉴权
  //   }
  // },
  {
    //NFC页
    path: "/Connect",
    name: "Connect",
    component: () => import("@/views/Connect.vue"),
    meta: {
      auth: true, //是否登录鉴权
      keepAlive: true
    }
  },

  {
    //应用首页
    path: "/Home",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      auth: true //是否登录鉴权
    }
  },
  {
    //应用首页（新版）
    path: "/NewHome",
    name: "NewHome",
    component: () => import("@/views/NewHome.vue"),
    meta: {
      auth: true, //是否登录鉴权
      keepAlive: true
    }
  },
  {
    //找回密码
    path: "/Retrieve",
    name: "Retrieve",
    component: () => import("@/views/Retrieve.vue"),
    meta: {
      auth: false //是否登录鉴权
    }
  },
  {
    //注册页面
    path: "/Register",
    name: "Register",
    component: () => import("@/views/Register.vue"),
    meta: {
      auth: false //是否登录鉴权
    }
  },
  {
    //历史记录
    path: "/History",
    name: "History",
    component: () => import("@/views/History.vue"),
    meta: {
      auth: true //是否登录鉴权
    }
  },
  {
    //历史记录
    path: "/MyBrand",
    name: "MyBrand",
    component: () => import("@/views/MyBrand.vue"),
    meta: {
      auth: true,//是否登录鉴权
      keepAlive: true
    }
  },
  {
    //历史记录
    path: "/PrivacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy.vue"),
    meta: {
      auth: false,//是否登录鉴权
      keepAlive: false
    }
  },
  {
    //历史记录
    path: "/BrandRecord",
    name: "BrandRecord",
    component: () => import("@/views/BrandRecord.vue"),
    meta: {
      auth: true //是否登录鉴权
    }
  },
  {
    //历史记录
    path: "/AppSettings",
    name: "AppSettings",
    component: () => import("@/views/AppSettings.vue"),
    meta: {
      auth: true //是否登录鉴权
    }
  },
  {
    //历史记录
    path: "/ThemeTemplate",
    name: "ThemeTemplate",
    component: () => import("@/views/ThemeTemplate.vue"),
    meta: {
      auth: false //是否登录鉴权
    }
  },
  {
    path: "*",
    redirect: {
      name: "Login"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

let isFirstLoad = true;
router.beforeEach((to, from, next) => {
  //to要跳转到的路径
  //from从哪个路径来
  //next往下执行的回调
  //在localStorage中获取token

  let token = window.localStorage.getItem(TOKEN);

  //判断该页面是否需要登录
  //如果token存在直接跳转
  if (token) {
    // Store.commit("setLoginInfo", userInfo);
    if (from.fullPath === "/") {
      //看看上次访问的是Connect 还是蓝牙

      const hash = window.location.hash.replace("#/","") || getQueryVariable("hash");
      store.commit("setHash", hash);

      if (isFirstLoad) {
        isFirstLoad = false;
        const lastVisitPath = localStorage.getItem("last_visit_path");
        if (lastVisitPath) {
          // next({ name: lastVisitPath, query: to.query });
          next();
        } else {
          next();
        }
        return;
      }
    }

    next();
  } else {
    if (to.meta.auth) {
      next({
        path: "/",
        //跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      //如果不需要登录鉴权，则直接跳转到对应页面
      next();
    }
  }

});

export default router;
